import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  //static page
  getStaticList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.STATIC + url,
      })
      .then((res) => res.data);
  },

  createStatic(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.STATIC,
        data,
      })
      .then((res) => res.data);
  },

  editStatic(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.STATIC + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemStatic(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.STATIC + url,
      })
      .then((res) => res.data);
  },

  deleteStatic(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.STATIC + url,
      })
      .then((res) => res.data);
  },

  //seo page

  getSeoList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.SEO + url,
      })
      .then((res) => res.data);
  },

  createSeo(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.SEO,
        data,
      })
      .then((res) => res.data);
  },

  editSeo(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.SEO + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemSeo(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.SEO + url,
      })
      .then((res) => res.data);
  },

  deleteSeo(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.SEO + url,
      })
      .then((res) => res.data);
  },

  getSeoListFilter(url = "", data) {
    return callApi.request({
      method: "POST",
      url: URL.SEO + "/filter" + url,
      data,
    });
  },
};
