/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import userApi from "~/api/user";
import ROUTE from "~/constants/routes";
import router from "~/router";
import { setAuthData, logOut, setUserData } from "../helpers/userAuth";
Vue.use(Vuex);

const state = {
  userInfo: null,
  users: {},
  usersList: [],
  user: null,
  userGroups: {},
  userGroupsList: [],
  userGroup: null,
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setUserGroupsList: (state, payload) => {
    state.userGroupsList = payload;
  },
  setUserGroups: (state, payload) => {
    state.userGroups = payload;
  },
  setUsersList: (state, payload) => {
    state.usersList = payload;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },
};

const actions = {
  login({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setError", {});
    commit("setValidationErrors", {});

    userApi
      .login(payload)
      .then((res) => {
        setAuthData(res.data.data.accessToken, res.data.data.expiresIn, {});
        dispatch("getUser");
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  logout({ commit }) {
    commit("setLoading", true);
    userApi
      .logout()
      .then((res) => {
        logOut();
        router.push("/login");
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getUser({ commit }) {
    userApi
      .getProfile()
      .then((res) => {
        setUserData(res.data.data.items);
        router.push("/");
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },

  getUserGroupsList({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .getUserGroupsList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setUserGroupsList", res.data[0].data);
        commit("setUserGroups", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createUserGroup({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .createUserGroup(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USER_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editUserGroup({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editUserGroup(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USER_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getUsersList({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .getUsersList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const users = res.data[0].data;
        for (let i = 0; users.length > i; i++) {
          users[i].group = users[i]?.groups[0]?.name;
        }
        commit("setUsersList", users);
        commit("setUsers", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createUser({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .createUser(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USERS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editUser({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editUser(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USERS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const users = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default users;
