/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import categoriesApi from "~/api/categories";
import ROUTE from "~/constants/routes";
import router from "~/router";
import { categoriesGroupsTableList, categoriesTableList } from "~/helpers/dataIteration";

Vue.use(Vuex);

const state = {
  categoriesGroups: [],
  categoriesGroupsList: [],
  categoriesGroup: {},
  categoriesGroupsTree: [],
  categories: [],
  categoriesList: [],
  category: {},
  categoriesTree: [],
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setCategoriesGroupsList: (state, payload) => {
    state.categoriesGroupsList = payload;
  },
  setCategoriesGroups: (state, payload) => {
    state.categoriesGroups = payload;
  },
  setCategoriesGroup: (state, payload) => {
    state.categoriesGroup = payload;
  },
  setCategoriesGroupsTree: (state, payload) => {
    state.categoriesGroupsTree = payload;
  },
  setCategoriesList: (state, payload) => {
    state.categoriesList = payload;
  },
  setCategories: (state, payload) => {
    state.categories = payload;
  },
  setCategory: (state, payload) => {
    state.category = payload;
  },
  setCategoriesTree: (state, payload) => {
    state.categoriesTree = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  // Categories Groups Methods Start

  getCategoriesGroupsList({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .getCategoriesGroupsList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setCategoriesGroupsList", categoriesGroupsTableList(res.data.items.data));
        commit("setCategoriesGroups", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemCategoriesGroup({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .getItemCategoriesGroup(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setCategoriesGroup", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createCategoriesGroup({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .createCategoriesGroup(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editCategoriesGroup({ commit }, { url, editData }) {
    commit("setLoading", true);
    categoriesApi
      .editCategoriesGroup(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  categoriesGroupCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .categoriesGroupCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Category Group has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getCategoriesGroupsTree({ commit }) {
    commit("setLoading", true);
    categoriesApi
      .getCategoriesGroupsTree()
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setCategoriesGroupsTree", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Categories Groups Methods End

  // Categories Methods Start

  getCategoriesList({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .getCategoriesList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        console.log(res);
        commit("setCategoriesList", categoriesTableList(res.data.items.data));
        commit("setCategories", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemCategory({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .getItemCategory(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setCategory", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createCategory({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .createCategory(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CATEGORIES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editCategory({ commit }, { url, editData }) {
    commit("setLoading", true);
    categoriesApi
      .editCategory(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CATEGORIES_LIST.replace(":page", 1),
          });
        } else {
          commit("setError", { message: res.message[0] });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  categoryCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    categoriesApi
      .categoryCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Category Group has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getCategoriesTree({ commit }) {
    commit("setLoading", true);
    categoriesApi
      .getCategoriesTree()
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setCategoriesTree", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Categories Methods End
};

const getters = {};

const categories = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default categories;
