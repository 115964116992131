/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import blogApi from "~/api/blog";
import ROUTE from "~/constants/routes";
import router from "~/router";
import {
  blogGroupsTableList,
  blogCategoriesTableList,
  blogPostsTableList,
} from "~/helpers/dataIteration";

Vue.use(Vuex);

const state = {
  blogGroups: {},
  blogGroupsList: [],
  blogGroup: null,

  blogCategories: {},
  blogCategoriesList: [],
  blogCategory: null,

  blogPosts: {},
  blogPostsList: [],
  blogPost: null,

  blogPostStatusesList: [],
  blogPostStatuses: {},

  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setBlogGroupsList: (state, payload) => {
    state.blogGroupsList = payload;
  },
  setBlogGroups: (state, payload) => {
    state.blogGroups = payload;
  },
  setBlogGroup: (state, payload) => {
    state.blogGroup = payload;
  },

  setBlogCategoriesList: (state, payload) => {
    state.blogCategoriesList = payload;
  },
  setBlogCategories: (state, payload) => {
    state.blogCategories = payload;
  },
  setBlogCategory: (state, payload) => {
    state.blogCategory = payload;
  },

  setBlogPostsList: (state, payload) => {
    state.blogPostsList = payload;
  },
  setBlogPosts: (state, payload) => {
    state.blogPosts = payload;
  },
  setBlogPost: (state, payload) => {
    state.blogPost = payload;
  },

  setBlogPostStatusesList: (state, payload) => {
    state.blogPostStatusesList = payload;
  },
  setBlogPostStatuses: (state, payload) => {
    state.blogPostStatuses = payload;
  },

  setError: (state, payload) => {
    state.error = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  // Blog Categories Groups start

  getBlogGroupsList({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .getBlogGroupsList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setBlogGroupsList", blogGroupsTableList(res.data[0].data));
        commit("setBlogGroups", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createBlogGroup({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .createBlogGroup(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editBlogGroup({ commit }, { url, editData }) {
    commit("setLoading", true);
    blogApi
      .editBlogGroup(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_GROUPS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  blogGroupCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .blogGroupCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          console.log("hello");
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Blog Group has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //Blog Categories Groups end

  //Blog Categories start

  getBlogCategoriesList({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .getBlogCategoriesList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setBlogCategoriesList", blogCategoriesTableList(res.data[0].data));
        commit("setBlogCategories", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createBlogCategory({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .createBlogCategory(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_CATEGORIES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editBlogCategory({ commit }, { url, editData }) {
    commit("setLoading", true);
    blogApi
      .editBlogCategory(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_CATEGORIES_LIST.replace(":page", 1),
          });
        } else {
          commit("setError", { message: res.message[0] });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  blogCategoryCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .blogCategoryCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          console.log("hello");
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Blog Category has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //Blog Categories end

  //Blog Posts start

  getBlogPostsList({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .getBlogPostsList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setBlogPostsList", blogPostsTableList(res.data[0].data));
        commit("setBlogPosts", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createBlogPost({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .createBlogPost(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_POSTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editBlogPost({ commit }, { url, editData }) {
    commit("setLoading", true);
    blogApi
      .editBlogPost(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_POSTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  blogPostCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .blogPostCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          console.log("hello");
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Blog Post has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //Blog Post end

  // Blog Post Statuses Start

  getBlogPostStatuses({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .getBlogPostStatuses(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setBlogPostStatuses", res.data[0]);
        commit("setBlogPostStatusesList", res.data[0].data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createBlogPostStatus({ commit }, payload) {
    commit("setLoading", true);
    blogApi
      .createBlogPostStatus(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_POST_STATUSES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editBlogPostStatus({ commit }, { url, editData }) {
    commit("setLoading", true);
    blogApi
      .editBlogPostStatus(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.BLOG_POST_STATUSES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Blog Post Statuses End
};

const getters = {};

const blog = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default blog;
