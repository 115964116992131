import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  //Categories Groups Methods Start

  getCategoriesGroupsList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_GROUP_LIST + url,
      })
      .then((res) => res.data);
  },

  createCategoriesGroup(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CATEGORIES_GROUP_LIST,
        data,
      })
      .then((res) => res.data);
  },

  editCategoriesGroup(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.CATEGORIES_GROUP_LIST + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemCategoriesGroup(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_GROUP_LIST + url,
      })
      .then((res) => res.data);
  },

  deleteCategoriesGroup(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.CATEGORIES_GROUP_LIST + url,
      })
      .then((res) => res.data);
  },

  categoriesGroupCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_GROUP_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  getCategoriesGroupsTree() {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_GROUPS_TREE,
      })
      .then((res) => res.data);
  },

  //Categories Groups Methods End

  //Categories Methods Start

  getCategoriesList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  getCategoriesActive(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_GET_ACTIVE + url,
      })
      .then((res) => res.data);
  },

  getCategoriesListFilter(url = "", data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CATEGORIES_FILTER + url,
        data,
      })
      .then((res) => res.data);
  },

  createCategory(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CATEGORIES_MAIN_URL,
        data,
      })
      .then((res) => res.data);
  },

  editCategory(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.CATEGORIES_MAIN_URL + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemCategory(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  deleteCategory(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.CATEGORIES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  categoryCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORY_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  getCategoriesTree() {
    return callApi
      .request({
        method: "GET",
        url: URL.CATEGORIES_TREE,
      })
      .then((res) => res.data);
  },

  categoriesImagesUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.CATEGORIES_IMAGE,
        data,
      })
      .then((res) => res.data);
  },

  categoriesImagesDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.CATEGORIES_IMAGE,
        data,
      })
      .then((res) => res.data);
  },

  //Categories Methods End
};
