import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  login(data) {
    return callApi.request({
      method: "POST",
      url: URL.LOG_IN + "?admin=1",
      data,
    });
  },

  getProfile() {
    return callApi.request({
      method: "POST",
      url: URL.GET_PROFILE,
    });
  },

  logout() {
    return callApi.request({
      method: "POST",
      url: URL.LOG_OUT,
    });
  },

  getUserGroupsList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.USER_GROUPS + url,
      })
      .then((res) => res.data);
  },

  createUserGroup(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.USER_GROUPS,
        data,
      })
      .then((res) => res.data);
  },

  editUserGroup(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.USER_GROUPS + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemUserGroup(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.USER_GROUPS + url,
      })
      .then((res) => res.data);
  },

  deleteUserGroup(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.USER_GROUPS + url,
      })
      .then((res) => res.data);
  },

  getUsersList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  createUser(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.USER,
        data,
      })
      .then((res) => res.data);
  },

  editUser(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.USER + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemUser(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  deleteUser(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  getUsersListFilter(url = "", data) {
    return callApi
      .request({
        method: "POST",
        url: URL.USER_FILTER + url,
        data,
      })
      .then((res) => res.data);
  },

  getUserDownloads(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_DOWNLOADS + url,
      })
      .then((res) => res.data);
  },
};
