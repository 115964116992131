import { dateTransform } from "./dateHelper";
// Table lists

export const categoriesGroupsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        status: data[i].status,
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const categoriesTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        url: data[i].url,
        name: data[i].fullName,
        seo_name: data[i].seoName,
        status: data[i].status,
        updated_at: dateTransform(data[i].updatedAt),
        templates_count: data[i].templatesCount || 0,
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        updated_at: dateTransform(data[i].updatedAt),
        created_at: dateTransform(data[i].createdAt),
        downloads: data[i].downloads,
        url: data[i].url,
      };
      if (data[i].statusId == 1) {
        list.status_id = "Waiting for copywriter";
      }
      if (data[i].statusId == 2) {
        list.status_id = "Waiting for approval";
      }
      if (data[i].statusId == 5) {
        list.status_id = "Scheduled";
      }
      if (data[i].statusId == 6) {
        list.status_id = "Published";
      }
      if (data[i].statusId == 3) {
        list.status_id = "Soft reject";
      }
      if (data[i].statusId == 4) {
        list.status_id = "Hard reject";
      }
      if (data[i].statusId == 7) {
        list.status_id = "Error";
      }

      if (data[i].statusId == 8) {
        list.status_id = "Waiting to be processed";
      }

      if (data[i].images.length > 0) {
        for (let e = 0; data[i].images[0].length > e; e++) {
          if (data[i].images[0][e].preview) {
            list.images_preview = data[i].images[0][e].url.preview[0];
          } else {
            list.images = data[i].images[0][e].url.preview[0];
          }
        }
      }
      if (data[i].categories != undefined) {
        if (data[i].categories.length > 0) {
          let categories = "";
          for (let a = 0; data[i].categories.length > a; a++) {
            if (a === 0) {
              categories += data[i].categories[a].categoryId;
            } else {
              categories += `, ${data[i].categories[a].categoryId}`;
            }
          }
          list.categories = categories;
        }
      }

      if (data[i].premium) {
        list.premium = "Premium";
      } else {
        list.premium = "Free";
      }
      if (data[i].createdBy[0]) {
        list.created_by = data[i].createdBy[0].email;
      } else {
        list.created_by = "";
      }

      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesTypesTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        status: data[i].status,
        updated_at: dateTransform(data[i].updatedAt),
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesStatusesTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        updated_at: dateTransform(data[i].updatedAt),
        count: data[i].count,
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesFormatsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        updated_at: dateTransform(data[i].updatedAt),
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesTagsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        updated_at: dateTransform(data[i].updatedAt),
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const templatesFontsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        url: data[i].url,
        updated_at: dateTransform(data[i].updatedAt),
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const blogGroupsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        updated_at: dateTransform(data[i].updatedAt),
        status: data[i].status,
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const blogCategoriesTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        updated_at: dateTransform(data[i].updatedAt),
        status: data[i].status,
      };
      tableList.push(list);
    }
  }
  return tableList;
};

export const blogPostsTableList = (data) => {
  const tableList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        seo_name: data[i].seoName,
        updated_at: dateTransform(data[i].updatedAt),
      };
      if (data[i].statusId == 1) {
        list.status_id = "Waiting for copywriter";
      }
      if (data[i].statusId == 2) {
        list.status_id = "Waiting for approval";
      }
      if (data[i].statusId == 5) {
        list.status_id = "Scheduled";
      }
      if (data[i].statusId == 6) {
        list.status_id = "Published";
      }
      if (data[i].statusId == 3) {
        list.status_id = "Soft reject";
      }
      if (data[i].statusId == 4) {
        list.status_id = "Hard reject";
      }
      if (data[i].statusId == 7) {
        list.status_id = "Error";
      }

      tableList.push(list);
    }
  }
  return tableList;
};

// Send form

export const categorySend = (data) => {
  let sendForm = {};
  if (data) {
    sendForm = {
      name: data.name != undefined ? data.name.trim() : "",
      seo_name: data.seo_name != undefined ? data.seo_name.trim() : "",
      h1: data.h1 != undefined ? data.h1.trim() : "",
      title: data.title != undefined ? data.title.trim() : "",
      description: data.description != undefined ? data.description.trim() : "",
      annotation: data.annotation != undefined ? data.annotation.trim() : "",
      anchor_title: data.anchor_title != undefined ? data.anchor_title.trim() : "",
      status: data.active ? 1 : 0,
      show_in_menu: data.show_in_menu ? 1 : 0,
      show_main: data.show_main ? 1 : 0,
    };
    if (data.seo_text != null) {
      sendForm.seo_text = data.seo_text.replace(
        /(<a\b[^>]*?)\brel\s*=\s*['"][^'"]*?['"]([^>]*?>)/gi,
        "$1$2",
      );
    } else {
      sendForm.seo_text = "";
    }
    sendForm.parent_id = data.parent_category;
    if (data.groups != null) {
      sendForm.group_id = data.groups;
    }
  }
  return sendForm;
};

// Set form data

export const categorySet = (data) => {
  let setForm = {};
  if (data) {
    setForm.name = data.name;
    setForm.seo_name = data.seoName;
    setForm.seo_text = data.seoText;
    setForm.h1 = data.h1;
    setForm.title = data.title;
    setForm.description = data.description;
    setForm.active = data.status == 1 ? true : false;
    setForm.show_in_menu = data.showInMenu == 1 ? true : false;
    setForm.annotation = data.annotation;
    setForm.anchor_title = data.anchorTitle;
    setForm.img = [];
    setForm.imgUrl = [];
    if (data.faqGroup != null) {
      setForm.faq_group = data.faqGroup.faqGroupId;
    }
    if (data.groupId != null) {
      setForm.groups = data.groupId;
    }
    if (data.parentId != 0) {
      setForm.parent_category = data.parentId;
    }
  }
  return setForm;
};
