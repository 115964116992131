/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import faqApi from "~/api/faq";
import ROUTE from "~/constants/routes";
import router from "~/router";
Vue.use(Vuex);

const state = {
  faqGroup: {},
  faqGroupList: [],
  faqItem: {},
  faqItemList: [],
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setFaqGroupList: (state, payload) => {
    state.faqGroupList = payload;
  },
  setFaqGroup: (state, payload) => {
    state.faqGroup = payload;
  },
  setFaqItemList: (state, payload) => {
    state.faqItemList = payload;
  },
  setFaqItem: (state, payload) => {
    state.faqItem = payload;
  },
};

const actions = {
  // FAQ Group

  getFaqGroupList({ commit }, payload) {
    commit("setLoading", true);
    faqApi
      .getFaqGroupList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const faq = res.data[0].data;
        commit("setFaqGroupList", faq);
        commit("setFaqGroup", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createFaqGroup({ commit }, payload) {
    commit("setLoading", true);
    faqApi
      .createFaqGroup(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FAQ_GROUP_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editFaqGroup({ commit }, { url, editData }) {
    commit("setLoading", true);
    faqApi
      .editFaqGroup(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FAQ_GROUP_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // FAQ Item

  getFaqItemList({ commit }, payload) {
    commit("setLoading", true);
    faqApi
      .getFaqItemList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const faq = res.data[0].data;
        commit("setFaqItemList", faq);
        commit("setFaqItem", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createFaqItem({ commit }, payload) {
    commit("setLoading", true);
    faqApi
      .createFaqItem(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FAQ_ITEM_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editFaqItem({ commit }, { url, editData }) {
    commit("setLoading", true);
    faqApi
      .editFaqItem(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FAQ_ITEM_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const faq = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default faq;
