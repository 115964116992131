import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  // Templates Types Start

  getTemplatesTypes(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_TYPES + url,
      })
      .then((res) => res.data);
  },

  createTemplatesType(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_TYPES,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesType(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_TYPES + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesType(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_TYPES + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesType(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_TYPES + url,
      })
      .then((res) => res.data);
  },

  // Templates Types End

  // Templates Statuses Start

  getTemplatesStatuses(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STATUSES + url,
      })
      .then((res) => res.data);
  },

  createTemplatesStatus(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_STATUSES,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesStatus(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_STATUSES + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesStatus(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STATUSES + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesStatus(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_STATUSES + url,
      })
      .then((res) => res.data);
  },

  // Templates Status End

  // Templates Color Start

  getTemplatesColor(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_COLOR + url,
      })
      .then((res) => res.data);
  },

  getTemplatesColorAll() {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_COLOR_ALL,
      })
      .then((res) => res.data);
  },

  createTemplatesColor(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_COLOR,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesColor(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_COLOR + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesColor(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_COLOR + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesColor(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_COLOR + url,
      })
      .then((res) => res.data);
  },

  // Templates Color End

  // Templates Style Start

  getTemplatesStyle(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STYLE + url,
      })
      .then((res) => res.data);
  },

  getTemplatesStyleAll() {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STYLE_ALL,
      })
      .then((res) => res.data);
  },

  createTemplatesStyle(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_STYLE,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesStyle(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_STYLE + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesStyle(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STYLE + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesStyle(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_STYLE + url,
      })
      .then((res) => res.data);
  },

  // Templates Style End

  // Templates Formats Start

  getTemplatesFormats(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_FORMATS + url,
      })
      .then((res) => res.data);
  },

  createTemplatesFormat(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_FORMATS,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesFormat(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_FORMATS + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesFormat(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_FORMATS + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesFormat(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_FORMATS + url,
      })
      .then((res) => res.data);
  },

  // Templates Formats End

  // Templates Tags Start

  getTemplatesTags(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_TAGS + url,
      })
      .then((res) => res.data);
  },

  getTemplatesTagsAll(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_TAGS_ALL + url,
      })
      .then((res) => res.data);
  },

  createTemplatesTag(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_TAGS,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesTag(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_TAGS + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesTag(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_TAGS + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesTag(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_TAGS + url,
      })
      .then((res) => res.data);
  },

  // Templates Tag End

  // Templates Fonts Start

  getTemplatesFonts(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_FONTS + url,
      })
      .then((res) => res.data);
  },

  getTemplatesFontsAll(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_FONTS_ALL + url,
      })
      .then((res) => res.data);
  },

  createTemplatesFont(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_FONTS,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesFont(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_FONTS + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesFont(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_FONTS + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesFont(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_FONTS + url,
      })
      .then((res) => res.data);
  },

  filterTemplatesFont(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_FONTS_FILTER,
        data,
      })
      .then((res) => res.data);
  },

  // Templates Fonts End

  // Templates Sizes Start

  getTemplatesSizes(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_SIZES + url,
      })
      .then((res) => res.data);
  },

  getTemplatesSizesAll(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_SIZES_ALL + url,
      })
      .then((res) => res.data);
  },

  createTemplatesSizes(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_SIZES,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesSizes(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_SIZES + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesSizes(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_SIZES + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesSizes(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_SIZES + url,
      })
      .then((res) => res.data);
  },

  // Templates Sizes End

  // Layout Fonts Start

  getLayoutFonts(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.LAYOUT_FONTS + url,
      })
      .then((res) => res.data);
  },

  createLayoutFonts(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.LAYOUT_FONTS,
        data,
      })
      .then((res) => res.data);
  },

  editLayoutFonts(url, data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.LAYOUT_FONTS + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemLayoutFonts(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.LAYOUT_FONTS + url,
      })
      .then((res) => res.data);
  },

  deleteLayoutFonts(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.LAYOUT_FONTS + url,
      })
      .then((res) => res.data);
  },

  filterLayoutFonts(url, data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LAYOUT_FONTS_FILTER + url,
        data,
      })
      .then((res) => res.data);
  },

  // Layout Fonts End

  // Plans Items Types Start

  getPlansItemsTypes() {
    return callApi
      .request({
        method: "GET",
        url: URL.CONTENT_PLAN_ITEM_TYPES,
      })
      .then((res) => res.data);
  },

  // Plans Items Types End

  // Templates Attributes Start

  getTemplatesAttributes(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_ATTRIBUTES + url,
      })
      .then((res) => res.data);
  },

  getTemplatesAttributesAll() {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_ATTRIBUTES + "/get-all",
      })
      .then((res) => res.data);
  },

  createTemplatesAttributes(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_ATTRIBUTES,
        data,
      })
      .then((res) => res.data);
  },

  editTemplatesAttributes(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_ATTRIBUTES + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplatesAttributes(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_ATTRIBUTES + url,
      })
      .then((res) => res.data);
  },

  deleteTemplatesAttributes(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_ATTRIBUTES + url,
      })
      .then((res) => res.data);
  },

  // Templates Attributes End

  // Crop

  imageCrop(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.CROP,
        data,
      })
      .then((res) => res.data);
  },

  imageConvert(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.IMAGE_CONVERT,
        data,
      })
      .then((res) => res.data);
  },

  imageRemoveBackground(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.REMOVE_BACKGROUND,
        data,
      })
      .then((res) => res.data);
  },

  getImageRemoveBackground(url) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "GET",
        url: URL.REMOVE_BACKGROUND_IMAGE + url,
      })
      .then((res) => res.data);
  },
};
