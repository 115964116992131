import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  // Blog Categories Groups start

  getBlogGroupsList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_GROUPS_MAIN + url,
      })
      .then((res) => res.data);
  },

  createBlogGroup(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.BLOG_GROUPS_MAIN,
        data,
      })
      .then((res) => res.data);
  },

  editBlogGroup(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.BLOG_GROUPS_MAIN + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemBlogGroup(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_GROUPS_MAIN + url,
      })
      .then((res) => res.data);
  },

  deleteBlogGroup(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_GROUPS_MAIN + url,
      })
      .then((res) => res.data);
  },

  blogGroupCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_GROUPS_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  blogGroupsGetTree() {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_GROUPS_GET_TREE,
      })
      .then((res) => res.data);
  },

  // Blog Categories Groups end

  // Blog Categories start

  getBlogCategoriesList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_CATEGORIES_MAIN + url,
      })
      .then((res) => res.data);
  },

  createBlogCategory(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.BLOG_CATEGORIES_MAIN,
        data,
      })
      .then((res) => res.data);
  },

  editBlogCategory(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.BLOG_CATEGORIES_MAIN + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemBlogCategory(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_CATEGORIES_MAIN + url,
      })
      .then((res) => res.data);
  },

  deleteBlogCategory(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_CATEGORIES_MAIN + url,
      })
      .then((res) => res.data);
  },

  blogCategoryCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_CATEGORIES_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  blogCategoriesGetTree() {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_CATEGORIES_GET_TREE,
      })
      .then((res) => res.data);
  },

  // Blog Categories end

  // Blog Posts start

  getBlogPostsList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POSTS_MAIN + url,
      })
      .then((res) => res.data);
  },

  createBlogPost(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.BLOG_POSTS_MAIN,
        data,
      })
      .then((res) => res.data);
  },

  editBlogPost(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.BLOG_POSTS_MAIN + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemBlogPost(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POSTS_MAIN + url,
      })
      .then((res) => res.data);
  },

  deleteBlogPost(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_POSTS_MAIN + url,
      })
      .then((res) => res.data);
  },

  blogPostCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POSTS_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  blogPostImagesUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.BLOG_POST_IMAGE,
        data,
      })
      .then((res) => res.data);
  },

  blogPostImagesDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_POST_IMAGE,
        data,
      })
      .then((res) => res.data);
  },

  blogPostImagesContentUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.BLOG_POST_IMAGE_CONTENT,
        data,
      })
      .then((res) => res.data);
  },

  blogPostImagesContentDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_POST_IMAGE_CONTENT,
        data,
      })
      .then((res) => res.data);
  },

  blogPostImageSizes() {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POST_IMAGE_SIZES,
      })
      .then((res) => res.data);
  },

  // Blog Posts end

  // Blog Posts Statuses Start

  getBlogPostStatuses(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POST_STATUSES + url,
      })
      .then((res) => res.data);
  },

  createBlogPostStatus(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.BLOG_POST_STATUSES,
        data,
      })
      .then((res) => res.data);
  },

  editBlogPostStatus(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.BLOG_POST_STATUSES + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemBlogPostStatus(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.BLOG_POST_STATUSES + url,
      })
      .then((res) => res.data);
  },

  deleteBlogPostStatus(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.BLOG_POST_STATUSES + url,
      })
      .then((res) => res.data);
  },

  // Blog Post Status End
};
