import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  // FAQ Group

  getFaqGroupList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.FAQ_GROUP + url,
      })
      .then((res) => res.data);
  },

  getFaqGroups() {
    return callApi
      .request({
        method: "GET",
        url: URL.FAQ_GROUP + "/getActive",
      })
      .then((res) => res.data);
  },

  createFaqGroup(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.FAQ_GROUP,
        data,
      })
      .then((res) => res.data);
  },

  editFaqGroup(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.FAQ_GROUP + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemFaqGroup(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.FAQ_GROUP + url,
      })
      .then((res) => res.data);
  },

  deleteFaqGroup(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.FAQ_GROUP + url,
      })
      .then((res) => res.data);
  },

  // FAQ Item

  getFaqItemList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.FAQ_ITEM + url,
      })
      .then((res) => res.data);
  },

  createFaqItem(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.FAQ_ITEM,
        data,
      })
      .then((res) => res.data);
  },

  editFaqItem(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.FAQ_ITEM + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemFaqItem(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.FAQ_ITEM + url,
      })
      .then((res) => res.data);
  },

  deleteFaqItem(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.FAQ_ITEM + url,
      })
      .then((res) => res.data);
  },
};
