/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import langApi from "~/api/lang";
import ROUTE from "~/constants/routes";
import router from "~/router";
Vue.use(Vuex);

const state = {
  lang: {},
  langList: [],
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setLangList: (state, payload) => {
    state.langList = payload;
  },
  setLang: (state, payload) => {
    state.lang = payload;
  },
};

const actions = {
  getLangList({ commit }, payload) {
    commit("setLoading", true);
    langApi
      .getLangList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const lang = res.data[0].data;
        commit("setLangList", lang);
        commit("setLang", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createLang({ commit }, payload) {
    commit("setLoading", true);
    langApi
      .createLang(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.LANG_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editLang({ commit }, { url, editData }) {
    commit("setLoading", true);
    langApi
      .editLang(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.LANG_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const lang = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default lang;
