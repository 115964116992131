import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getSubscriptionList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.SUBSCRIPTION + url,
      })
      .then((res) => res.data);
  },

  getUserActiveSubscription(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_USER_ACTIVE_SUBSCRIPTION + url,
      })
      .then((res) => res.data);
  },

  getUserSubscription(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_USER_SUBSCRIPTION + url,
      })
      .then((res) => res.data);
  },

  assignSubscription(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ASSIGN_SUBSCRIPTION,
        data,
      })
      .then((res) => res.data);
  },

  deAssignSubscription(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.DE_ASSIGN_SUBSCRIPTION,
        data,
      })
      .then((res) => res.data);
  },

  createSubscription(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.SUBSCRIPTION,
        data,
      })
      .then((res) => res.data);
  },

  editSubscription(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.SUBSCRIPTION + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemSubscription(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.SUBSCRIPTION + url,
      })
      .then((res) => res.data);
  },

  deleteSubscription(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.SUBSCRIPTION + url,
      })
      .then((res) => res.data);
  },
};
