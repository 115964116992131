/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import socialApi from "~/api/social";
import ROUTE from "~/constants/routes";
import router from "~/router";
Vue.use(Vuex);

const state = {
  FB: {},
  FBList: [],

  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },

  setFBList: (state, payload) => {
    state.FBList = payload;
  },
  setFB: (state, payload) => {
    state.FB = payload;
  },
};

const actions = {
  getFBList({ commit }, payload) {
    commit("setLoading", true);
    socialApi
      .getFBList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const fb = res.data[0].data;
        console.log(fb);
        commit("setFBList", fb);
        commit("setFB", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const social = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default social;
