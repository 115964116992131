/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import subscriptionApi from "~/api/subscription";
import ROUTE from "~/constants/routes";
import router from "~/router";
Vue.use(Vuex);

const state = {
  subscription: {},
  subscriptionList: [],
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setSubscriptionList: (state, payload) => {
    state.subscriptionList = payload;
  },
  setSubscription: (state, payload) => {
    state.Subscription = payload;
  },
};

const actions = {
  getSubscriptionList({ commit }, payload) {
    commit("setLoading", true);
    subscriptionApi
      .getSubscriptionList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const subscription = res.data[0].data;
        // for (let i = 0; users.length > i; i++) {
        //   users[i].group = users[i]?.groups[0]?.name;
        // }
        commit("setSubscriptionList", subscription);
        commit("setSubscription", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createSubscription({ commit }, payload) {
    commit("setLoading", true);
    subscriptionApi
      .createSubscription(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.SUBSCRIPTION_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editSubscription({ commit }, { url, editData }) {
    commit("setLoading", true);
    subscriptionApi
      .editSubscription(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.SUBSCRIPTION_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const subscription = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default subscription;
