import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getFBList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.SOCIAL_FB + url,
      })
      .then((res) => res.data);
  },

  createFB(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.SOCIAL_FB,
        data,
      })
      .then((res) => res.data);
  },

  deleteFB(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.SOCIAL_FB + url,
      })
      .then((res) => res.data);
  },
};
