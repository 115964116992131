/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import staticsApi from "~/api/static";
import ROUTE from "~/constants/routes";
import router from "~/router";
Vue.use(Vuex);

const state = {
  statics: {},
  staticsList: [],

  seo: {},
  seoList: [],

  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },

  setStaticsList: (state, payload) => {
    state.staticsList = payload;
  },
  setStatics: (state, payload) => {
    state.statics = payload;
  },

  setSeoList: (state, payload) => {
    state.seoList = payload;
  },
  setSeo: (state, payload) => {
    state.seo = payload;
  },
};

const actions = {
  // Static Page

  getStaticsList({ commit }, payload) {
    commit("setLoading", true);
    staticsApi
      .getStaticList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const faq = res.data[0].data;
        commit("setStaticsList", faq);
        commit("setStatics", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createStatics({ commit }, payload) {
    commit("setLoading", true);
    staticsApi
      .createStatic(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.STATIC_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editStatics({ commit }, { url, editData }) {
    commit("setLoading", true);
    staticsApi
      .editStatic(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.STATIC_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // SEO

  getSeoList({ commit }, payload) {
    commit("setLoading", true);
    staticsApi
      .getSeoList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const seo = res.data[0].data;
        commit("setSeoList", seo);
        commit("setSeo", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createSeo({ commit }, payload) {
    commit("setLoading", true);
    staticsApi
      .createSeo(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.SEO_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editSeo({ commit }, { url, editData }) {
    commit("setLoading", true);
    staticsApi
      .editSeo(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.SEO_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const statics = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default statics;
