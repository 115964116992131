import Vue from "vue";
import Vuex from "vuex";

import categories from "./categories";
import templates from "./templates";
import references from "./references";
import users from "./users";
import blog from "./blog";
import subscription from "./subscription";
import faq from "./faq";
import statics from "./statics";
import social from "./social";
import lang from "./lang";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categories,
    users,
    templates,
    references,
    blog,
    subscription,
    faq,
    statics,
    social,
    lang,
  },
});
